import React from "react";
import { Typography, Box, Grid, Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import { bookingText } from "./bookingText";
import PageTitle from "../all/PageTitle";
import text from "../../textInsert/text.json";

const useStyles = makeStyles((styleTheme) => ({
  iframe: {
    height: 400,
    width: "auto",
    marginTop: 10,
  },
  callButton: {
    backgroundColor: "#9d65c9",
    color: "white",
    "&:hover": { backgroundColor: styleTheme.palette.grey["500"] },
  },
  bookButton: {
    backgroundColor: "#9d65c9",
    color: "white",
    "&:hover": { backgroundColor: styleTheme.palette.grey["500"] },
  },
}));

const Booking: React.FC = () => {
  const classes = useStyles();
  let theme = useTheme();
  theme = responsiveFontSizes(theme);
  return (
    <ThemeProvider theme={theme}>
      <Box textAlign='center' my={8}>
        <PageTitle title='Booking' />
        <Box my={5}>
          <Grid container justify='center'>
            <Grid item xs={12} sm={6} lg={4}>
              <Box
                textAlign='center'
                fontWeight='fontWeightBold'
                fontSize='h3.fontSize'
                p={2}
              >
                ONLINE BOOKING
              </Box>
              <Box textAlign='center'>
                <Button
                  href={bookingText.online.bookingLink}
                  target='_blank'
                  variant='contained'
                  size='large'
                  className={classes.bookButton}
                >
                  {bookingText.online.bookingOnlineText}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Box
                textAlign='center'
                fontWeight='fontWeightBold'
                fontSize='h3.fontSize'
                p={2}
              >
                PHONE BOOKING
              </Box>
              <Box textAlign='center'>
                <Button
                  href={text.callNow}
                  variant='contained'
                  size='large'
                  className={classes.callButton}
                >
                  {text.phone}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Booking;
